<template>
  <sub-page-top
    title="お問い合わせ"
    height="300px"
    >
    <p>
      新規お取引のお申込み、料金・サービス等のお問い合わせは、<br>
      下記お問い合わせフォームにご入力のうえ「確認画面へ」ボタンを押してください。
    </p>
  </sub-page-top>
  <div class="contents">
    <div v-if="step === 1">
      <p class="title">お問い合わせフォーム</p>
      <div class="contact-form">
        <div class="caution">
          お問い合わせ内容によっては、お返事を差し上げるまでにお時間をいただく場合がございます。ご了承頂けるようお願い申し上げます。
          <!-- <p>
            ※<a target="_blank" href="https://www.chatwork.com/tony56">チャットワーク</a>でもお問合せ承っております。
          </p> -->
        </div>
        <dl>
          <dt>
            お名前
            <span class="required">必須</span>
          </dt>
          <dd class="btn-row">
            <input type="text" v-model="contactName" />
          </dd>          
          <dt class="label">
            Eメール/電話番号
            <span class="required">必須</span>
          </dt>
          <dd class="btn-row">
            <input type="text" v-model="contact" />
          </dd>
          <dt class="label">会社名</dt>
          <dd class="btn-row">
            <input type="text" v-model="companyName" />
          </dd>
          <dt class="label">中国輸入のご経験</dt>
          <dd class="btn-row">
            <input type="radio" name="importExp" value="なし" id="import-exp-no" v-model="hasExp">
            <label for="import-exp-no">なし</label>
            <input class="ml-2" type="radio" name="importExp" value="あり" id="import-exp-has" v-model="hasExp">            
            <label for="import-exp-has">あり</label>
          </dd>
          <dt>
            お問合せ内容
            <span class="required">必須</span>
          </dt>
          <dd class="btn-row">
            <textarea v-model="contactContent" />
          </dd>
        </dl>
      </div>
      <div class="submit">
        <button :disabled="!canSubmit" @click="confirm()">確認画面へ</button>
      </div>      
    </div>
    <div v-else-if="step === 2" class="confirm">
      <h2 class="align-center">お問合せ内容の確認</h2>
      <dl>
        <dt>お名前</dt>
        <dd class="text">
          {{contactName}}
        </dd>        
        <dt class="label">Eメール/電話番号</dt>
        <dd class="text">
          {{contact}}
        </dd>
        <dt class="label">会社名</dt>
        <dd class="text">
          {{companyName}}
        </dd>
        <dt class="label">中国輸入のご経験</dt>
        <dd class="text">
          {{hasExp}}
        </dd>
        <dt>お問合せ内容</dt>
        <dd>
          <pre class="text">{{contactContent}}</pre>
        </dd>
      </dl>
      <div class="submit">
        <button class="btn-secondary" @click="modify()">修正</button>
        <button :disabled="!canSubmit" @click="submit()">送信</button>
      </div>
    </div>
    <div v-else class="thanks">
      <h3 class="align-center">
        問合せて頂いて、ありがとうございます。<br>
        お問い合わせ内容によっては、お返事を差し上げるまでにお時間をいただく場合がございます。ご了承頂けるようお願い申し上げます。
      </h3>
    </div>
  </div>
</template>

<script>
import SubPageTop from '../components/molecules/SubPageTop.vue'

export default {
  name: 'News',
  components: {
    SubPageTop,
  },
  data() {
    return {
      contact: null,
      companyName: null,
      contactName: null,
      contactContent: null,
      step: 1,
      hasExp: 'なし',
    }
  },
  computed: {
    canSubmit() {
      return this.contact && this.contactContent && (this.companyName || this.contactName)
    }
  },
  methods: {
    confirm() {
      this.step = 2
    },
    modify() {
      this.step = 1
    },
    submit() {
      this.$api.post('https://cozhowm9fh.execute-api.ap-northeast-1.amazonaws.com/default/contact-form', {
        contact: this.contact,
        companyName: this.companyName,
        contactName: this.contactName,
        contactContent: `輸入経験${this.hasExp}, ` + this.contactContent,
      }).then(res => {
        console.log('response' + res)
        this.step = 3
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.sub-page-top {
  background-image: url('../assets/contact-top.png');
  background-size: cover;
}
input[type=text] {
  min-height: 27px;
  padding: 3px 10px;
  background: white;
  border: 1px solid #cee7f0;
  border-radius: 5px;
  margin-bottom: 15px;
}

textarea {
  padding: 10px 10px 10px 10px;
  background: white;
  border: 1px solid #cee7f0;
  width: 100%;
  border-radius: 5px;
  margin-bottom: 15px;
  min-height: 80px;
}

.text {
  margin-bottom: 10px;
  font-size: 14px;
}

pre {
  font-family: auto;
  margin: 0;
}

.submit {
  text-align: center;
  margin-top: 30px;
  button {
    background: orange;
    color: white;
    border: none;
    font-size: 16px;
    border-radius: 5px;
    padding: 5px 20px;
    margin: auto;
    cursor: pointer;
  }
  button:disabled {
    background: rgb(197, 181, 181);
  }
  .btn-secondary {
    background: rgb(189, 185, 185);
    margin-right: 30px;
  }
}

.contents {
  margin: auto;
  padding-top: 25px;
  padding-bottom: 50px;
  .caution {
    margin-bottom: 20px;
  }
  .title {
    font-size: 25px;
    font-weight: bold;
    text-align: center;
  }
  .contact-form {
    background: #F6F6F6;
    margin: auto;
    border-radius: 5px;
    margin-top: 20px;
    .required {
      background: red;
      color: white;
      border-radius: 10px;
      padding: 1px 8px;
      font-size: 12px;
      margin-left: 10px;
      display: flex;
      align-items: center;
      height: 20px;
    }
  }
  .confirm {
    margin: auto;
    width: 50%;
  }
}

@media (min-width: 751px) {
  .contents {
    min-height: 55vh;
    .contact-form {
      width: 70%;
      min-width: 600px;
      max-width: 780px;
      padding: 50px 90px;
      dl {
        dt {
          display: inline-flex;
          width: 30%;
        }
        dd {
          display: inline-flex;
          width: 60%;
          align-items: baseline;
          min-height: 40px;
        }
      }
      input[type=text] {
        width: 100%;
      }
    }    
  }
  .thanks {
    width: 60%;
    margin: auto;
  }
}

@media screen and (max-width: 750px) {
  .sub-page-top {
    background-image: url('../assets/contact-top-sp.png');
  }
 .contents {
    width: 90%;
    min-height: 46vh;
    .contact-form {
      padding: 30px 15px;
      dl {
        dt {
          display: flex;
          font-weight: bold;
        }
        dd {
          margin-inline-start: 0;
          margin-top: 10px;
          min-height: 40px;
        }
      }
      input[type=text], textarea {
        width: 92%;
      }
    }
  }
}

</style>